import {useTranslation} from "react-i18next";

import Container from "../UI/Container";
import SectionHeader from "../UI/header/SectionHeader";
import ProjectCard from "../UI/block/card/ProjectCard";

import './OurProjectSection.sass';
import project1 from '../../assets/img/projects/project1.png';
import project2 from '../../assets/img/projects/project2.png';
import project3 from '../../assets/img/projects/project3.png';
import project4 from '../../assets/img/projects/project4.png';
import project6 from '../../assets/img/projects/project6.png';
import project7 from '../../assets/img/projects/project7.png';
import project9 from '../../assets/img/projects/project9.png';
import project10 from '../../assets/img/projects/project10.png';
import project11 from '../../assets/img/projects/project11.png';
import project12 from '../../assets/img/projects/project12.png';
import project13 from '../../assets/img/projects/project13.png';
import project14 from '../../assets/img/projects/project14.png';
import project15 from '../../assets/img/projects/project15.png';
import project16 from '../../assets/img/projects/project16.jpg';
import project17 from '../../assets/img/projects/project17.jpg';

const OurProjectsSection = (props) => {
    const { t } = useTranslation();

    return (
        <section className='our-projects' id='ourProjects' ref={props.sectionRef}>
            <Container>
                <SectionHeader header={t("ourProjects.header.title")}>
                    {t("ourProjects.header.description")}
                </SectionHeader>
                <div className='projects-grid'>
                    <ProjectCard
                        header={t("ourProjects.projects.project15.title")}
                        description={t("ourProjects.projects.project15.description")}
                        image={project15}
                        url='https://gpartners.md/ro/'
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project16.title")}
                        description={t("ourProjects.projects.project16.description")}
                        image={project16}
                        url='https://greenworldgbr.de/de/'
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project17.title")}
                        description={t("ourProjects.projects.project17.description")}
                        image={project17}
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project14.title")}
                        description={t("ourProjects.projects.project14.description")}
                        image={project14}
                        url='https://www.behance.net/gallery/178860351/Real-estate-consulting-branding-2023'
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project13.title")}
                        description={t("ourProjects.projects.project13.description")}
                        image={project13}
                        url='https://www.behance.net/gallery/192461415/soncept-of-corp-attributes-for-an-insurance-company'
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project1.title")}
                        description={t("ourProjects.projects.project1.description")}
                        image={project1}
                        url='https://blackmoto.md/'
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project3.title")}
                        description={t("ourProjects.projects.project3.description")}
                        image={project3}
                        url='https://torsedor.md/'
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project2.title")}
                        description={t("ourProjects.projects.project2.description")}
                        image={project2}
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project4.title")}
                        description={t("ourProjects.projects.project4.description")}
                        image={project4}
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project6.title")}
                        description={t("ourProjects.projects.project6.description")}
                        image={project6}
                        url='https://www.behance.net/gallery/179019961/Psychologist-landing-page-website'
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project7.title")}
                        description={t("ourProjects.projects.project7.description")}
                        image={project7}
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project9.title")}
                        description={t("ourProjects.projects.project9.description")}
                        image={project9}
                        url='https://www.behance.net/gallery/178467887/Branding-for-private-profiling-services'
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project10.title")}
                        description={t("ourProjects.projects.project10.description")}
                        image={project10}
                        url='https://www.behance.net/gallery/151443533/Premium-tabacco-branding-e-commerce-website'
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project11.title")}
                        description={t("ourProjects.projects.project11.description")}
                        image={project11}
                        url='https://www.behance.net/gallery/151380121/Medical-equipment-business-website-UIUX'
                    />
                    <ProjectCard
                        header={t("ourProjects.projects.project12.title")}
                        description={t("ourProjects.projects.project12.description")}
                        image={project12}
                        url='https://www.behance.net/gallery/159360229/Hoodie-Store-Identity'
                    />
                </div>
            </Container>
        </section>
    );
};

export default OurProjectsSection;